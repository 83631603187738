import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';
import ArrowLink from '../components/ArrowLink';
import SideImageInfoSection from '../components/SideImageInfoSection';
import Button from '../components/Button';
import RelatedInsights from '../components/RelatedInsights';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import mdToHtml from '../util/mdToHtml';
import { PageWrap } from '../styles/common';

export const IndustryDetailTemplate = ({
	displayName,
	mastheadImage,
	sideImage,
	infoContent,
	servicesContent,
	experts,
}) => {
	return (
		<PageWrap>
			<Masthead image={mastheadImage} title={displayName} />
			<SideImageInfoSection
				image={sideImage}
				imageAlt={`Industry image.`}
				isLeft={false}
				mainContent={infoContent}
			/>
			{experts.length && (
				<Experts>
					<div className="container" style={{ padding: '0 2rem' }}>
						<div style={{ textAlign: 'center' }}>
							<h2>{displayName} Experts:</h2>
						</div>
						<div
							className="columns is-multiline"
							style={{ marginBottom: '4rem' }}
						>
							{experts.map((member, index) => (
								<div className="column is-half" key={index}>
									<TrusteeTile>
										<ImageWrap>
											<img src={member.round_image} alt="" />
										</ImageWrap>
										<ContentWrap>
											<h3>{member.name}</h3>
											<h4>{member.title}</h4>
											<ArrowLink href={member.fields.slug}>Contact</ArrowLink>
										</ContentWrap>
									</TrusteeTile>
								</div>
							))}
						</div>
						<div style={{ textAlign: 'center' }}>
							<ArrowLink href="/team">SEE OUR FULL TEAM</ArrowLink>
						</div>
					</div>
				</Experts>
			)}
			<RelatedWrap>
				<div style={{ textAlign: 'center' }}>
					<h3>Featured Articles:</h3>
				</div>
				<RelatedInsights />
			</RelatedWrap>
			<ServicesWrap>
				<div className="container" style={{ padding: '0 2rem' }}>
					<div
						dangerouslySetInnerHTML={{
							__html: mdToHtml(servicesContent),
						}}
						style={{ marginBottom: '4rem' }}
					/>
					<h3>Solutions for Business Owners</h3>
					<TagWrap>
						{businessServices.map((service, index) => (
							<a href={service.link} index={index} key={index}>
								<Button>{service.service}</Button>
							</a>
						))}
					</TagWrap>
					<h3>Solutions for Lenders & Creditors</h3>
					<TagWrap>
						{lendersServices.map((service, index) => (
							<a href={service.link} index={index} key={index}>
								<Button>{service.service}</Button>
							</a>
						))}
					</TagWrap>
				</div>
			</ServicesWrap>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const IndustryDetail = ({ data }) => {
	const {
		display_name,
		masthead_image,
		info_section,
		services_content,
	} = data.industriesJson;
	const experts = filterExperts(
		data.team.edges.map((edge) => edge.node),
		display_name
	);

	return (
		<Layout>
			<Helmet>
				<title>{display_name} | Spergel Corporate</title>
			</Helmet>
			<IndustryDetailTemplate
				displayName={display_name}
				mastheadImage={masthead_image ? masthead_image : null}
				sideImage={
					info_section && info_section.side_image
						? info_section.side_image
						: null
				}
				infoContent={
					info_section && info_section.info_content
						? info_section.info_content
						: null
				}
				servicesContent={services_content ? services_content : null}
				experts={experts}
			/>
		</Layout>
	);
};

export default IndustryDetail;

export const industryDetailQuery = graphql`
	query Industry($id: String!) {
		industriesJson(id: { eq: $id }) {
			display_name
			masthead_image
			info_section {
				side_image
				info_content
			}
			services_content
			fields {
				slug
			}
		}
		team: allTeamJson {
			edges {
				node {
					vcard
					bio
					name
					portrait_image
					round_image
					title
					certifications
					phone_number
					office
					email
					industries {
						industry
					}
					services {
						service
					}
					fields {
						slug
					}
				}
			}
		}
	}
`;

const filterExperts = (experts, industry) => {
	return experts.filter((expert) => {
		if (expert.industries) {
			return expert.industries.some((i) => i.industry === industry);
		} else {
			return false;
		}
	});
};

const businessServices = [
	{
		service: 'Incorporated Business Debt',
		link: '/services/incorporated-business-debt-solutions/',
	},
	{
		service: 'CRA Debt',
		link: '/services/cra-debt/',
	},
	{
		service: 'Sole Proprietor Business Debt',
		link: '/services/sole-proprietor-business-debt-solutions/',
	},
	{
		service: 'Negotiating with Lenders',
		link: '/services/negotiating-with-your-lender/',
	},
	{
		service: 'Restructuring',
		link: '/services/restructuring-business/',
	},
	{
		service: 'Insolvency',
		link: '/services/insolvency-business/',
	},
	{
		service: 'Business Advisory',
		link: '/services/business-advisory-business-owners/',
	},
	{
		service: 'Estate Trustee Litigation',
		link: '/services/estate-trustee-during-litigation/',
	},
	{
		service: 'Spergel Corporate Finance',
		link: '/services/spergel-corporate-finance/',
	},
];

const lendersServices = [
	{
		service: 'Business Advisory',
		link: '/services/business-advisory-lenders/',
	},
	{
		service: 'Insolvency',
		link: '/services/insolvency-lenders/',
	},
	{
		service: 'Investigative Receivership',
		link: '/services/investigative-receivership/',
	},
	{
		service: 'Consulting to Asset-Based Lenders',
		link: '/services/consulting-to-asset-based-lenders/',
	},
	{
		service: 'Remedies for Landlord',
		link: '/services/remedies-for-landlord/',
	},
	{
		service: 'Remedies for Franchisor',
		link: '/services/remedies-for-franchisor/',
	},
];

const TrusteeTile = styled.div`
	display: flex;
	margin: 0;
	@media only screen and (min-width: 768px) {
		margin: 1rem;
	}
`;

const ImageWrap = styled.div`
	flex-basis: 160px;
	img {
		width: 100%;
	}
`;

const ContentWrap = styled.div`
	flex-basis: 200px;
	flex-grow: 1;
	padding: 1rem 2rem;
	a {
		margin-left: 0;
		font-size: 1rem;
	}
	h3 {
		@media only screen and (min-width: 768px) {
			font-size: 2rem;
		}
		font-size: 1.25rem;
		margin: 0;
		font-weight: 300;
	}
	h4 {
		margin: 0;
		font-weight: 300;
		margin-bottom: 1rem;
	}
`;

const Experts = styled.div`
	background-color: #f2f2f2;
	padding: 4rem 0;
`;

const TagWrap = styled.div`
	button {
		margin-right: 1rem;
		margin-bottom: 1rem;
		font-size: 0.85rem;
		font-weight: 400;
	}
	width: 100%;
`;

const ServicesWrap = styled.div`
	h2 {
		font-size: 2rem;
		margin-bottom: 4rem;
	}
	h3 {
		font-size: 1.5rem;
		margin-bottom: 2rem;
	}
	background-color: #f2f2f2;
	padding: 4rem 0;
`;

const RelatedWrap = styled.div`
	background-color: white;
	padding: 4rem 0 6rem 0;

	h2 {
		font-weight: 700;
	}
	h3 {
		font-weight: 400;
		font-size: 1.75rem;
		margin-bottom: 4rem;
	}
	a {
		margin-left: 0;
		color: inherit;
	}
`;
