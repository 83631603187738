import React from 'react';
import styled from 'styled-components';

import mdToHtml from '../util/mdToHtml';

const Wrap = styled.div`
	min-height: 400px;
	display: flex;
	flex-direction: ${(p) => (p.isLeft ? 'row' : 'row-reverse')};
`;

const Aside = styled.div`
	position: relative;
	margin: 0;
	img {
		height: 400px;
		width: auto;
		display: block;
	}
	background-color: ${(p) => p.bg};
	flex-basis: 400px;
	display: none;
	@media only screen and (min-width: 1088px) {
		display: block;
	}
`;

const AsideContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: ${(p) => (p.isLeft ? '4rem 2rem' : '2rem 4rem')};
	color: white;
	h2 {
		font-size: 2rem;
		font-weight: 400;
	}
`;

const Content = styled.div`
	flex-grow: 1;
	padding: 2rem 2rem;
	@media only screen and (min-width: 768px) {
		padding: 2rem 4rem;
	}
	h2 {
		font-size: 2rem;
		font-weight: 400;
		a {
			color: inherit;
		}
	}
	background-color: ${(p) => p.bg};
`;

export default ({ image, imageAlt, isLeft, sideContent, mainContent, bg }) => (
	<Wrap image={image} isLeft={isLeft}>
		<Aside bg={bg}>
			<img src={image} alt={imageAlt} />
			<AsideContent
				dangerouslySetInnerHTML={{ __html: mdToHtml(sideContent) }}
			/>
		</Aside>
		<Content
			dangerouslySetInnerHTML={{ __html: mdToHtml(mainContent) }}
			bg={bg}
		/>
	</Wrap>
);
