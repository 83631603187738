import React from 'react';
//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import ArrowLink from './ArrowLink';

export default () => {
	return (
		<div className="container" style={{ padding: '0 2rem' }}>
			<div className="columns">
				<div className="column">
					<h2>
						Lending to Your Company: Protecting Your Shareholder Loan, Part I
					</h2>
					<ArrowLink href="/insights/lending-to-your-company-protecting-your-shareholder-loan-part-i/">
						READ MORE
					</ArrowLink>
				</div>
				<div className="column">
					<h2>
						Lending to Your Company: Protecting Your Shareholder Loan, Part II
					</h2>
					<ArrowLink href="/insights/lending-to-your-company-protecting-your-shareholder-loan-part-ii/">
						READ MORE
					</ArrowLink>
				</div>
			</div>
		</div>
	);
};
