import React from 'react';
import styled from 'styled-components';

import common from '../styles/common';

const Wrap = styled.button`
	background-color: ${common.spergelRed};
	&:hover {
		background-color: ${common.lightSpergelRed};
	}
	transition: background-color ease 250ms;
	color: white;
	font-weight: bold;
	font-size: ${(p) => (p.size === 'large' ? '1.5rem' : '1rem')};
	border-radius: ${(p) => (p.size === 'large' ? '2.25rem' : '2rem')};
	padding: ${(p) => (p.size === 'large' ? '0.75rem 2rem' : '0.5rem 1.5rem')};
	cursor: pointer;
	text-transform: uppercase;
	font-family: ${common.baseFont};
	border: none;
	a {
		color: white;
		text-decoration: none;
	}
`;

export default (props) => {
	return (
		<Wrap size={props.size} {...props}>
			{props.children}
		</Wrap>
	);
};
